var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-icon',{attrs:{"icon":"stopwatch","font-scale":"3","animation":"cylon"}}),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v(" Creating Route. Please wait a moment. ")]),_c('b-icon',{attrs:{"icon":"stopwatch","font-scale":"3","animation":"cylon"}}),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Optimizing Route. Please wait a moment.")])],1)]},proxy:true}])},[_c('b-container',{attrs:{"fluid":""}},[_c('b-card',[_c('b-row',{staticClass:"align-items-center"},[_c('b-col',[_c('v-select',{staticClass:"font-small-3 w-100mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (selected_order_type) { return selected_order_type.value; },"options":_vm.orderTypeOptions,"clearable":true,"label":"label","placeholder":"Select Order Type","multiple":""},on:{"input":function($event){_vm.resetClients();
              _vm.displayMarker();}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.label))])]}}]),model:{value:(_vm.selected_order_type),callback:function ($$v) {_vm.selected_order_type=$$v},expression:"selected_order_type"}})],1),(_vm.userData.id == '1042')?_c('b-col',[_c('v-select',{staticClass:"font-small-3 w-100 mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.branchOptions,"clearable":true,"reduce":function (branchOptions) { return branchOptions.value; },"placeholder":"Select Outlet","label":"label"},on:{"input":function($event){_vm.resetClients();
              _vm.displayMarker();}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.label))])]}}],null,false,1193437580),model:{value:(_vm.selected_branch),callback:function ($$v) {_vm.selected_branch=$$v},expression:"selected_branch"}})],1):_vm._e(),_c('b-col',[_c('v-select',{staticClass:"font-small-3 w-100 mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusTypeOptions,"clearable":true,"reduce":function (statusTypeOptions) { return statusTypeOptions.value; },"placeholder":"Select Status Type","label":"label"},on:{"input":function($event){_vm.resetClients();
              _vm.displayMarker();}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.label))])]}}]),model:{value:(_vm.selected_status_type),callback:function ($$v) {_vm.selected_status_type=$$v},expression:"selected_status_type"}})],1),_c('b-col',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
              dateFormat: 'Y-m-d',
              defaultDate: 'today',
            }},on:{"input":function($event){_vm.resetClients();
              _vm.displayMarker();}},model:{value:(_vm.filter_date),callback:function ($$v) {_vm.filter_date=$$v},expression:"filter_date"}})],1),_c('b-col',{staticClass:"d-flex align-items-center"},[_vm._v(" SELECTED ORDERS : "),_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.counter))]),_c('b-tooltip',{attrs:{"target":"eye-icon","triggers":"hover","placement":"top"}},[_vm._v(" View List of Orders ")]),_c('b-icon-eye',{directives:[{name:"b-modal",rawName:"v-b-modal.OrderList",modifiers:{"OrderList":true}}],staticClass:"ml-1",attrs:{"id":"eye-icon"}})],1),_c('b-col',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{attrs:{"variant":"gradient-primary"},on:{"click":function($event){$event.preventDefault();return _vm.optimizeRouting()}}},[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"MapPinIcon","size":"14"}}),_c('span',{},[_vm._v(" Optimize Route ")])],1)],1)],1),_c('div',{ref:"map",staticClass:"mt-1",staticStyle:{"height":"800px"}})],1),_c('b-modal',{attrs:{"id":"OrderList","title":"Selected Orders List","no-enforce-focus":"","size":"lg"},on:{"hidden":_vm.removeFocus}},_vm._l((_vm.selectedOrders),function(order,index){return _c('b-card',{key:index,staticClass:"mb-2"},[_c('div',[_c('b-badge',{staticClass:"mr-1",attrs:{"variant":"primary"}},[_vm._v("OrderID: ")]),_c('strong',[_vm._v(_vm._s(order.id))])],1),_c('hr'),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-badge',{staticClass:"mr-1",attrs:{"variant":"secondary"}},[_vm._v("Address: ")]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-truncate d-inline-block",staticStyle:{"max-width":"600px"},attrs:{"title":order.address}},[_vm._v(" "+_vm._s(order.address)+" ")])],1)])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }